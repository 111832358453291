
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import {
  ICustomer,
  ICustomerRequest,
  ICustomerUpdateRequest
} from "@/types/customer";
import { CustomerRequest } from "@/models/customer";
import { IUser, IUserListRequest } from "@/types/user";
import { ISelectItem } from "@/types";
import { IMoneyforwardPartnerUpdateRequest } from "@/types/moneyforward";
import { MoneyforwardPartnerUpdateRequest } from "@/models/moneyforward";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //customer
  @Action("customer/userGet")
  public getCustomer!: (customer_id: number) => Promise<boolean>;

  @Action("customer/userUpdate")
  public update!: (data: {
    customer_id: number;
    params: ICustomerUpdateRequest;
  }) => Promise<boolean>;

  @Action("customer/userIsUniqueName")
  public isUniqueName!: (params: ICustomerRequest) => Promise<boolean>;

  @Action("customer/userIsUniqueShortName")
  public isUniqueShortName!: (params: ICustomerRequest) => Promise<boolean>;

  @Action("customer/userIsUniqueCustomerMfid")
  public isUniqueCustomerMfid!: (params: ICustomerRequest) => Promise<boolean>;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Action("customer/userIsDeleteRelationBranchUser")
  public isDeleteRelationBranchUser!: (customer_id: number) => Promise<boolean>;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //user
  @Action("user/userGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  //moneyforward
  @Action("moneyforward/getRefreshToken")
  public getRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/getReToken")
  public getReToken!: () => Promise<boolean>;

  @Action("moneyforward/createRefreshToken")
  public createRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/userUpdatePartner")
  public updatePartner!: (data: {
    partner_id: string;
    params: IMoneyforwardPartnerUpdateRequest;
  }) => Promise<boolean>;

  //パラメータ定義
  public params: ICustomerRequest = new CustomerRequest();
  public mf_params: IMoneyforwardPartnerUpdateRequest =
    new MoneyforwardPartnerUpdateRequest();

  //変数定義
  public customer_id = 0;
  public close_eom = false;
  public submit_dialog = false;
  public valid = true;
  public lazy = false;
  public name_errors: string[] = [];
  public short_name_errors: string[] = [];
  public customer_mfid_errors: string[] = [];
  public menu = {
    schedule_date: false
  };

  //ルール設定
  public rules = {
    name: [
      (v: string) => !!v || "顧客名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "顧客名は50文字以下で入力してください"
    ],
    kana: [
      (v: string) => !!v || "ﾖﾐｶﾞﾅは必須です",
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    cutoff_day: [
      (v: string) =>
        !v || /^[0-9]+$/.test(v) || "半角数字のみで入力してください"
    ],
    users: [(v: number[]) => !!v.length || "テクネ担当者は必須です"],
    registration_no: [(v: string) => !!v || "登録番号は必須です"],
    schedule_date: [(v: string) => !!v || "登録予定日は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearCustomer();
    this.customer_id = Number(this.$route.params.customer_id);

    await Promise.all([
      this.getUsers({ per_page: 0 }),
      this.getCustomer(this.customer_id),
      this.getRefreshToken()
    ]);
    await this.getReToken();
    await this.createRefreshToken();

    this.setDefault();

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromCustomer(this.customer);
    if (this.params.cutoff_day == 99) {
      this.close_eom = true;
    }
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    // マネーフォワード連携===========================================
    this.mf_params.createFromCustomer(this.params);

    result = await this.updatePartner({
      partner_id: this.params.uuid,
      params: this.mf_params
    });
    //================================================================

    if (result) {
      result = await this.update({
        customer_id: this.customer_id,
        params: this.params
      });
    }

    await this.isDeleteRelationBranchUser(this.customer_id);

    if (result) {
      this.$router.go(-1);
    }
  }

  // 顧客名の二重登録チェック
  public async name_validate() {
    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.customer.name != this.params.name
    ) {
      return (this.name_errors = ["既に使用されています"]);
    }

    return (this.name_errors = []);
  }

  // 短縮表示名の二重登録チェック
  public async short_name_validate() {
    // 編集時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      this.customer.short_name != this.params.short_name
    ) {
      return (this.short_name_errors = ["既に使用されています"]);
    }

    return (this.short_name_errors = []);
  }

  // 顧客IDの二重登録チェック
  public async customer_mfid_validate() {
    if (!this.params.customer_mfid) {
      return (this.customer_mfid_errors = []);
    }
    if (
      !(await this.isUniqueCustomerMfid(this.params)) &&
      this.customer.customer_mfid != this.params.customer_mfid
    ) {
      return (this.customer_mfid_errors = ["既に使用されています"]);
    }

    return (this.customer_mfid_errors = []);
  }

  // 登録ステータスを選んだ場合の挙動
  public registrationStatus() {
    this.params.schedule_date = "";
    this.params.registration_no = "";
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }
}
